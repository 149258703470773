import {createReducer} from 'redux-act'
import * as actions from '../actions/plan'
import {ActiveGoalConstants, Money, Plan} from "finhelper"
import moment from "moment/moment";

let data = {
  data: [],
  dataByYear: [],
  dataByMonth: [],
  planLoading: true,
};

function getPlanIncome(months, item, user) {

  //TODO review
  let income = Plan.calcIncome(months, item, user)
  let tax = item.tax;

  let countMonths = 0;

  months.map((row) =>
  {
    if(parseInt(row.month) === 1)
    {
      countMonths++;
    }
  });

  if (income > 0) {
    //по закону если доход больше 5 млн, то подоходный налог будет 15%
    //работает в том случае, если пользователь указано стандартный 13% налог на доходы
    if(tax === 13 && income >= 5000000)
    {
      //TODO не отличается логика если условие не выполняется
      return income / countMonths;
    }

    //показываем помесячно, поэтому нужно разделить на количество месяцев
    return income / countMonths;
  }
}

const handleGoals = (state, {response, client}) => {
  let data = state.data;

  let dataByYear = [];
  let dataByMonth = [];

  if(response.data && response.data.length)
  {
    response.data.map((responseItem) => {
      data.map((item, key) => {
        data[key]['with_plan_goals'] = 0;
        data[key]['without_plan_goals'] = 0;
        data[key]['plan_goals'] = 0;
        data[key]['retired_goals'] = 0;
        data[key]['fact_goals'] = 0;
      });
    });

    response.data.map((responseItem) => {
      data.map((item, key) => {
        responseItem.goal_payments.map((payment) => {
          let date = moment(payment.paid_at_date, 'DD.MM.YYYY');

          if (date.year() === parseInt(item.year)) {
            if (responseItem.plan_type_id === ActiveGoalConstants.WITH_PLAN) {
              data[key]['with_plan_goals'] += payment.sum;
            } else if (responseItem.plan_type_id === ActiveGoalConstants.WITHOUT_PLAN) {
              data[key]['without_plan_goals'] += payment.sum;
            }

            //если платёж относится к текущим плановым, то включаем его в план
            if (parseInt(payment.is_active)) {
              data[key]['plan_goals'] += payment.sum;
            }

            data[key]['fact_goals'] += payment.paid_sum;
          }
        });
      });
    });
  }

  dataByMonth = state.dataByMonth;

  data.map((item, key) =>
  {
    //добавляем плановаую пенсию к чистому доходу
    if(response.data && response.data.length)
    {
      response.data.filter((responseItem) => {
        return responseItem.type_id === ActiveGoalConstants.RETIRE
      }).map((responseItem) =>
      {
        let buyDate = moment(item.buy_at_date, 'DD.MM.YYYY');
        let retiredDate = moment(client.birth_at_date, 'DD.MM.YYYY').add(client.retired_age, 'year')
        let deadDate = moment(client.birth_at_date, 'DD.MM.YYYY').add(client.retired_age, 'year').add(client.dead_age, 'year')


        let achieve = responseItem?.data?.[0]?.calc?.achieve;
        if (buyDate.isAfter(retiredDate) && buyDate.isSameOrBefore(deadDate) && achieve)
        {
          //сумма цели должна уравнять сумму расходов
          //если цель достижима
          data[key]['retired_goals'] += item.outcome;
        }
      })
    }

    data[key].balance = Plan.balance(item);

    dataByYear[item.year] = item;

    let months = item.months ? item.months : ActiveGoalConstants.defaultMask(Money.toDigits(item.plan_income));

    let planIncome = getPlanIncome(months, item, client);

    months.map((month, key) =>
    {
      dataByMonth[item.year + '-' + (key + 1)] = {
        plan_income: planIncome,
        income_positive: 0,
        income_neutral: 0,
        income_negative: 0,
        income_positive_changed: 0,
        income_neutral_changed: 0,
        income_negative_changed: 0,
        additional_income: 0,
        tax: 0,
        outcome: 0,
        outcome_changed: 0,
        additional_outcome: 0
      };
    })
  });



  return {
    ...state,
    data: data,
    dataByYear: dataByYear,
    dataByMonth: dataByMonth,
    planLoading: false
  };
};


const handleFactIncome = (state, {response, client}) => {
  let data = state.data;

  //TODO access by key
  let year = null;
  let i = 0;

  for(let n = 0; n < data.length; n++)
  {
    data[n]['fact_goals'] = 0;
    data[n]['fact_income'] = 0;
    data[n]['fact_additional_income'] = 0;
    data[n]['fact_main_income'] = 0;
    data[n]['fact_base_outcome'] = 0;
    data[n]['fact_additional_outcome'] = 0;
  }

  response.data?.chart?.map((responseItem, responseKey) =>
  {
    if((responseKey + 1) > client.career_start_month)
    {
      i++;
      if(year === null)
      {
        let parts = responseItem.category.split('-')

        year = parseInt(parts[0]);
      }

      for(let n = 0; n < data.length; n++)
      {
        let itemYear = parseInt(data[n].year);

        if(year === itemYear)
        {
          let income = responseItem.income;
          let incomeAdditional = responseItem.income_additional;
          let incomeMain = responseItem.income_main;
          let outcome = responseItem.base_outcome;
          let additionalOutcome = responseItem.additional_outcome;

          data[n]['fact_income'] += income;
          data[n]['fact_additional_income'] += incomeAdditional;
          data[n]['fact_main_income'] += incomeMain;
          data[n]['fact_base_outcome'] += outcome;
          data[n]['fact_additional_outcome'] += additionalOutcome;

          break;
        }
      }

      if(i === 12)
      {
        year++;
        i = 0;
      }
    }
  });

  return {
    ...state,
    data: data
  };
};

const handleObligations = (state, {response, client}) => {

  let data = state.data;

  if(data && data.length)
  {
    data.map((item, key) =>
    {
      data[key]['fact_obligation'] = 0;
      data[key]['obligation'] = 0;

      let year = moment(item.buy_at_date, 'DD.MM.YYYY').format('Y');

      if(response.data && response.data.length > 0)
      {
        response.data.map((obligation) =>
        {
          obligation.payments.map((payment) =>
          {
            let paymentYear = moment(payment.paid_at_date, 'DD.MM.YYYY').format('Y');

            if(year === paymentYear)
            {
              data[key]['obligation'] += Math.abs(payment.sum);

              if(payment.is_confirmed)
              {
                data[key]['fact_obligation'] += Math.abs(payment.sum);
              }
            }
          })
        });
      }

    });
  }

  return {
    ...state,
    data: data
  };
};

const handlePlan = (state, {response, client}) =>
{
  if(response.data && response.data.length)
  {
    let data = response.data.map((item) =>
    {
      item['income_positive'] = Money.format(item.income_positive);
      item['income_neutral'] = Money.format(item.income_neutral);
      item['income_negative'] = Money.format(item.income_negative);
      item['income_positive_changed'] = item.income_positive_changed;
      item['income_neutral_changed'] = item.income_neutral_changed;
      item['income_negative_changed'] = item.income_negative_changed;
      item['additional_income'] = Money.format(item.additional_income);
      item['tax'] = Money.format(item.tax);
      item['tax_changed'] = item.tax_changed;
      item['outcome'] = Money.format(item.outcome);
      item['outcome_changed'] = item.outcome_changed;
      item['additional_outcome'] = Money.format(item.additional_outcome);

      if(typeof item['obligation'] === 'undefined')
      {
        item['obligation'] = 0;
      }
      if(typeof item['plan_goals'] === 'undefined')
      {
        item['plan_goals'] = 0;
      }
      if(typeof item.plan_income === 'undefined')
      {
        item.plan_income = 0;
      }

      if (item['income_negative'] || item['income_neutral'] || item['income_positive'])
      {
        item.plan_income = Plan.calcIncome([], item, client);
      }

      //проверяем что структура заполнен по старой логике или не заполнена вообще и сохраняем новую логику
      if(item['months'] && (item['months'].length === 0 || item['months'].length && typeof item['months'][0].rows === 'undefined'))
      {
        item['months'] = ActiveGoalConstants.defaultMask(Money.toDigits(item.plan_income));
      }

      return item;
    });

    return {
      ...state,
      data: data
    };
  }

  return {
    ...state,
    data: []
  };
}

const handlePlanLogout = (state) => {
  return {
    data: [],
    dataByYear: [],
    dataByMonth: [],
    planLoading: true
  }
}

const setNeedReloadPlan = (state) => {
  return {
    data: [],
    dataByYear: [],
    dataByMonth: [],
    planLoading: true,
  }
}

const setLoading = (state, loading) => {
  return {
    ...state,
    planLoading: loading,
  }
}

const reducer = createReducer({
  [actions.handlePlan]: handlePlan,
  [actions.handleObligations]: handleObligations,
  [actions.handleFactIncome]: handleFactIncome,
  [actions.handleGoals]: handleGoals,
  [actions.handlePlanLogout]: handlePlanLogout,
  [actions.setNeedReloadPlan]: setNeedReloadPlan,
}, data)

export default reducer
